import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginGuardService} from './login-guard.service';
import {OrganizationGuardService} from './organization-guard.service';

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},
    {path: 'home', loadChildren: './home/home.module#HomePageModule', canActivate: [OrganizationGuardService]},
    {path: 'visualization/:collectionId', loadChildren: './visualization/visualization.module#VisualizationPageModule'},
    {path: 'login', loadChildren: './login/login.module#LoginPageModule'},
    {
        path: 'select-organization',
        loadChildren: './select-organization/select-organization.module#SelectOrganizationPageModule',
        canActivate: [LoginGuardService]
    },
    {
        path: 'link-grasp',
        loadChildren: './link-grasp/link-grasp.module#LinkGraspPageModule',
        canActivate: [LoginGuardService]
    },
    { path: 'auth/:uuid/:time/:hash', loadChildren: './auth/auth.module#AuthPageModule' },
    {
        path: 'select-collection/:organizationId/:graspId',
        loadChildren: './select-collection/select-collection.module#SelectCollectionPageModule',
        canActivate: [LoginGuardService]
    },
  { path: 'email-confirmation/:id/:token', loadChildren: './email-confirmation/email-confirmation.module#EmailConfirmationPageModule' },
  { path: 'day-list/:collectionId', loadChildren: './day-list/day-list.module#DayListPageModule' },
  { path: 'barcode/:collectionId', loadChildren: './barcode/barcode.module#BarcodePageModule' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
