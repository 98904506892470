import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {GraspService} from './grasp.service';

@Injectable()
export class LoginGuardService implements CanActivate {

    constructor(private router: Router, private graspService: GraspService) {

    }

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            this.graspService.isAuthenticated()
                .then((token) => {
                    if (token) {
                        resolve(true);
                    } else {
                        this.router.navigate(['login']);
                        resolve(false);
                    }
                })
                .catch(err => {
                    this.router.navigate(['login']);
                    resolve(false);
                });
        })
    }
}
