import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {API_URL} from '../environments/environment';

import { Observable, zip} from 'rxjs/index';
import { map, flatMap, reduce } from 'rxjs/operators';
import { from, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GraspService {
    private static API_URL_LOGIN           = API_URL + 'users/login';
    private static API_URL_REGISTER        = API_URL + 'users/register';
    private static API_URL_FORGOT_PASSWORD = API_URL + 'users/forgot_password';
    private static API_URL_USER            = API_URL + 'users';
    private static API_URL_ORGANIZATIONS   = API_URL + 'organizations';
    private static API_URL_OBSERVATIONS    = API_URL + 'observations';
    private static API_URL_GRASPS          = API_URL + 'grasps';
    private static API_URL_LINK            = API_URL + 'grasps/link';

    public token;
    public user;
    public organization;

    constructor(private http: HttpClient, private storage: Storage) {
        this.storage.get('token').then(token => this.token = token );
        this.storage.get('user').then(user => this.user = user );
    }

    login(username, password) {
        return this.http.post(GraspService.API_URL_LOGIN, {
            email: username,
            password: password
        }).pipe(
            map(
                res => {
                    this.setSession(res['data'].user, res['data'].token)
                    return this.user;
                }
            )
        );
    }

    setSession(user, token) {
        this.user  = user;
        this.token = token;
        this.storage.set('user', this.user);
        this.storage.set('token', this.token);
    }

    auth(token, organization) {
        this.token = token;
        this.storage.set('token', this.token);
        this.organization = organization;
        this.storage.set('organization', this.organization);
    }

    logout() {
        this.storage.remove('token');
        this.storage.remove('user');
        this.storage.remove('organization');
        this.token = null;
        this.user = null;
        this.organization = null;
    }

    getToken(uuid, time, hash) {
        return this.http.get(
            GraspService.API_URL_GRASPS + '/' + uuid + '/get_visualisation_token?time=' + time + '&time_hash=' + hash
        )
            .pipe(map(result => result['data']));
    }

    forgotPassword(email) {
        return this.http.post(
            GraspService.API_URL_FORGOT_PASSWORD,
            { 'user_email': email}
        )
            .pipe(map(result => result['data']));
    }

    register(firstName, lastName, email, password) {
        return this.http.post(
            GraspService.API_URL_REGISTER,
            { 'first_name': firstName, 'last_name': lastName, 'email': email,  'password': password, 'culture': 'enUS' },
            {headers: {'Authorization': 'Bearer ' + this.token}}
        )
            .pipe(map(result => result['data']));
    }

    confirmEmail(id, token) {
        return this.http.post(
            GraspService.API_URL_USER + '/confirm_email',
            { 'id': id, 'email_confirmation_code': token },
            {}
        )
            .pipe(map(result => {
                const data = result['data'];
                this.setSession(data.user, data.token);
                return data;
            }));
    }

    isLoggedIn() {
        return !!this.token;
    }

    isAuthenticated() {
        return this.storage.get('token');
    }

    link(linkCode) {
        return this.http.post(
            GraspService.API_URL_LINK,
            { link_code: linkCode },
            {headers: {'Authorization': 'Bearer ' + this.token}}
        )
            .pipe(map(result => result['data']));
    }

    unlink(uuid) {
        return this.http.delete(
            GraspService.API_URL_GRASPS + '/' + uuid + '/unlink',
            {headers: {'Authorization': 'Bearer ' + this.token}}
        );
    }

    getLinkCode(uuid) {
        return this.http.get(
            GraspService.API_URL_GRASPS + '/' + uuid + '/link_code',
            {headers: {'Authorization': 'Bearer ' + this.token}}
        )
            .pipe(map(result => result['data']));
    }

    setOrganization(organization: any) {
        this.organization = organization;
        this.storage.set('organization', this.organization);
    }

    getOrganization(): Promise<any> {
        return this.storage.get('organization');
    }

    getTokenAndOrganization$() {
        return zip(from(this.getOrganization()), from(this.storage.get('token')));
    }

    getOrganizations(): Observable <any[]> {
        return this.getAllPages(GraspService.API_URL_USER + '/' + this.user.id + '/organizations');
    }

    getGrasps(): Observable <any[]> {
        return from(this.getOrganization())
            .pipe(flatMap(organization => this.getAllPages(GraspService.API_URL_USER + '/' + this.user.id + '/organizations/' + organization.id + '/grasps'))
        );
    }

    getGraspDetails(deviceUuid) {
        return this.http.get(
            GraspService.API_URL_GRASPS + '/' + deviceUuid + '/grasp_details'
        )
            .pipe(map(result => result['data']));
    }

    getGraspCollections(graspId, organizationId): Observable <any[]> {
        return from(this.storage.get('token'))
            .pipe(flatMap(token => this.getAllPages(GraspService.API_URL_ORGANIZATIONS + '/' + organizationId + '/grasps/' + graspId + '/collections')))
    }

    getCollections(): Observable<any[]> {
        return from(this.getOrganization())
            .pipe(flatMap(organization => this.getAllPages(GraspService.API_URL_USER + '/' + this.user.id + '/organizations/' + organization.id + '/collections')))
    }

    getSuggestedCollections(dataset): Observable<any[]> {
        return from(this.getOrganization())
            .pipe(flatMap(organization => this.getAllPages(GraspService.API_URL_ORGANIZATIONS + '/' + organization.id + '/observations/' + dataset.id + '/suggested_collections')))
    }

    assignDatasetToCollection(dataset, collection) {
        return from(this.getOrganization())
            .pipe(flatMap(organization => this.http.patch(
                GraspService.API_URL_ORGANIZATIONS + '/' + organization.id + '/observations/' + dataset.id + '/assign_to_collection',
                {
                    'collection_id': collection.id,
                },
                {headers: {'Authorization': 'Bearer ' + this.token}}
            )))
            .pipe(map(result => result['data']));
    }

    createCollection(name) {
        return from(this.getOrganization())
            .pipe(flatMap(organization => this.http.post(
                GraspService.API_URL_ORGANIZATIONS + '/' + organization.id + '/collections',
                {name: name},
                {headers: {'Authorization': 'Bearer ' + this.token}}
                )
            )
        );
    }

    addGraspToCollection(grasp, collection) {
        return from(this.getOrganization())
            .pipe(flatMap(organization => this.http.post(
                GraspService.API_URL_ORGANIZATIONS + '/' + organization.id + '/collections/' + collection.id + '/assign_grasp',
                {'user_id': this.user.id, 'grasp_id': grasp.id},
                {headers: {'Authorization': 'Bearer ' + this.token}}
                )
                )
            );
    }

    removeGraspFromCollection(grasp, collection) {
        return from(this.getOrganization())
            .pipe(flatMap(organization => this.http.post(
                GraspService.API_URL_ORGANIZATIONS + '/' + organization.id + '/collections/' + collection.id + '/unassign_grasp',
                {'user_id': this.user.id, 'grasp_id': grasp.id},
                {headers: {'Authorization': 'Bearer ' + this.token}}
                )
                )
            );
    }

    getUnassignedSets(): Observable <any[]> {
        return from(this.getOrganization())
            .pipe(flatMap(organization => this.getAllPages(
                GraspService.API_URL_USER + '/' + this.user.id + '/organizations/' + organization.id + '/orphaned_observations')))
    }

    getCollectionObservations(collectionId): Observable <any[]> {
        return this.getTokenAndOrganization$()
            .pipe(flatMap((result) => {
                const [organization, token] = result;
                return this.getAllPages(GraspService.API_URL_ORGANIZATIONS + '/' + organization.id + '/collections/' + collectionId + '/observations');
            }));
    }

    getCollectionObservationsFlatList(collectionId) {
        return this.getCollectionObservations(collectionId)
            .pipe(map(observations => {
                if (observations.length === 0) { return [of({'observations': []})]; }
                return observations.map(obs => { console.log(obs); return this.getObservationSet(obs.id); });
            }))
            .pipe(flatMap(observables => zip(...observables)))
            .pipe(map(observationSets => observationSets.map(set => set['observations'])))
            .pipe(map(sets => Array.prototype.concat(...sets)));
    }

    getObservations(grasp_id): Observable <any[]> {
        return this.getAllPages(GraspService.API_URL_OBSERVATIONS + '?grasp_id=' + grasp_id)
    }

    getObservationSet(setId) {
        return from(this.getOrganization())
            .pipe(flatMap(organization => this.http.get(
            GraspService.API_URL_ORGANIZATIONS + '/' + organization.id + '/observations/' + setId,
            {headers: {'Authorization': 'Bearer ' + this.token}}
        )))
            .pipe(map(result => result['data']));
    }

    getGrasp(uuid) {
        return this.http.get(
            GraspService.API_URL_GRASPS + '/' + uuid,
            {headers: {'Authorization': 'Bearer ' + this.token}}
        )
            .pipe(map(result => result['data']));
    }

    saveDataSet(endpoint, uuid, start, end, data) {
        this.http.post(
            endpoint,
            {
                "physical_device_uuid": uuid,
                "schema_version": "1",
                "sample_rate_in_hz": 1,
                "time_start": start,
                "time_end": end,
                "observations": [
                    {
                        "time_start": start,
                        "amplitudes": data
                    }
                ]
            },
            {headers: {'Authorization': 'Bearer ' + this.token}}
        );
    }

    getLocalGrasps() {
        return this.storage.get('localGrasps');
    }

    getUser(): Promise<any> {
        return this.storage.get('user');
    }

    saveLocalGrasps(grasps) {
        this.storage.set('localGrasps', grasps);
    }

    getAllPages(url, pageSize?) {
        pageSize = pageSize || 25;
        return this.getPagesSequentially(url + '?page_size=' + pageSize)
            .pipe(reduce((x, y) => x.concat(y['data']), []));
    }

    getPagesSequentially(url) {
        return Observable.create(observer => {
            this.getPage(url, 1, observer);
        });
    }

    async getPage(url, page, observer) {
        const result = await this.http.get(url + '&page=' + page, {headers: {'Authorization': 'Bearer ' + this.token }}).toPromise();
        observer.next(result);
        if (result['meta']['pagination'] && page < result['meta']['pagination']['pages_count']) { this.getPage(url, page + 1, observer); }
        else { observer.complete(); }
    }
}
